<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户名称">
                <a-input v-model="queryParam.userName" placeholder="请输入用户名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号码">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="角色">
                <a-select v-model="queryParam.roleId" placeholder="请选择角色">
                  <a-select-option :value="0">请选择角色</a-select-option>
                  <a-select-option v-for="item in conditionlist.roleList" :key="item.roleId" :value="item.roleId">
                    {{ item.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="使用状态">
                  <a-select v-model="queryParam.status" placeholder="请选择状态">
                    <a-select-option v-for="item in conditionlist.statusList" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="userId"
        :columns="columns"
        :data="loadData"
        showPagination="auto"
      >
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="userRoleStr" slot-scope="text">
          <ellipsis :length="24" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="setUserStatusApi(record.userId, 1)" v-if="record.status === 0" :disabled="(record.userId === currentUser.id) ? true : false">禁用</a>
            <a @click="setUserStatusApi(record.userId, 0)" v-else :disabled="(record.userId === currentUser.id) ? true : false">启用</a>
            <a-divider type="vertical" />
            <a @click="resetPassApi(record.userId)" :disabled="(record.userId === currentUser.id) ? true : false">重置密码</a>
            <a-divider type="vertical" />
            <a @click="gotoRangePower(record.userId)" :disabled="!record.isRange">分配区域</a>
            <br />
            <a @click="gotoAssignClassify(record.userInfoId, record.userId)">分配网格问题分类</a>
            <a-divider type="vertical" />
            <a @click="gotoAssignPoint(record.userInfoId, record.userId)">分配网格卡点</a>
          </template>
        </span>
      </s-table>

      <create-user-form
        :visible="visible"
        :loading="confirmLoading"
        @cancel="handleCancel"
        @ok="handleOk"
        ref="setuserModal"
      />
      <assign-classify ref="assignclassify"></assign-classify>
      <assign-point ref="assignpoint"></assign-point>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { Modal } from 'ant-design-vue'
import CreateUserForm from './modules/CreateUserForm'
import { resetPass, setUserStatus, userCondition, userList } from '@/api/user'
import AssignClassify from '@/views/grid/components/AssignClassify'
import AssignPoint from '@/views/grid/components/AssignPoint'
const columns = [
  {
    title: 'ID',
    dataIndex: 'userId'
  },
  {
    title: '用户名',
    dataIndex: 'userName'
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    title: '真实姓名',
    dataIndex: 'realName'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '角色',
    dataIndex: 'userRoleStr',
    scopedSlots: { customRender: 'userRoleStr' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '260px',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'success',
    text: '正常'
  },
  1: {
    status: 'default',
    text: '禁用'
  }
}

export default {
  name: 'UserList',
  components: {
    STable,
    Ellipsis,
    CreateUserForm,
    AssignClassify,
    AssignPoint
  },
  data () {
    this.columns = columns
    return {
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        userName: '',
        mobile: '',
        userId: 0,
        roleId: 0,
        status: 9
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return userList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      conditionlist: {
        statusList: [],
        roleList: []
      },
      currentUser: {}
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  beforeRouteLeave (to, from, next) {
    // 打开详情页（或者下一个任意界面）之前，把筛选条件保存到localstorage，如果离开列表页并且打开的不是详情页则清除，也可以选择不清除
    console.log(to.name)
    if (to.name === 'UseRangePower') {
      const condition = JSON.stringify(this.queryParam)
      localStorage.setItem('userListCondition', condition)
    } else {
      localStorage.removeItem('userListCondition')
    }
    next()
  },
  created () {
    if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
      this.queryParam.roleId = parseInt(this.$route.query.roleId)
    }
    // getRoleList({ t: new Date() })
    this.currentUser = this.$store.state.user.info
    console.log(this.$store.state.user)
    const searchCondition = localStorage.getItem('userListCondition')
    console.log('searchCondition', searchCondition)
    if (searchCondition !== null) {
      this.queryParam = JSON.parse(searchCondition)
    }
    this.userConditionApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'GridAdminList') {
        if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
          this.queryParam.roleId = parseInt(this.$route.query.roleId)
        }
        // getRoleList({ t: new Date() })
        this.currentUser = this.$store.state.user.info
        console.log(this.$store.state.user)
        const searchCondition = localStorage.getItem('userListCondition')
        console.log('searchCondition', searchCondition)
        if (searchCondition !== null) {
          this.queryParam = JSON.parse(searchCondition)
        }
        this.userConditionApi()
        this.$refs.table.refresh(true)
      }
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    userConditionApi () {
      const _this = this
      userCondition({}).then((res) => {
        if (res.errorCode === 0) {
          _this.conditionlist = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetPassApi (userId) {
      const _this = this
      Modal.confirm({
        title: '重置密码',
        content: '确认要重置该用户的密码吗？',
        onOk: () => {
          resetPass({ userId: userId }).then((res) => {
            if (res.errorCode === 0) {
              _this.$message.info(res.errorMsg)
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            _this.$message.error('系统错误')
            console.log(err)
          })
        },
        onCancel () {
          console.log('onCancel')
        }
      })
    },
    setUserStatusApi (userId, status) {
      const _this = this
      var title = (status === 1) ? '禁用用户' : '启用用户'
      var content = (status === 1) ? '确认要禁用该用户吗？' : '确认要启用该用户吗？'
      Modal.confirm({
        title: title,
        content: content,
        onOk: () => {
          setUserStatus({ userId: userId, status: status }).then((res) => {
            if (res.errorCode === 0) {
              _this.$message.info(res.errorMsg)
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            _this.$message.error('系统错误')
            console.log(err)
          })
        },
        onCancel () {
          console.log('onCancel')
        }
      })
    },
    handleAdd () {
      // this.mdl = null
      this.$refs.setuserModal.getUserInfo({ userId: 0 })
      this.visible = true
    },
    handleEdit (record) {
      const _this = this
      this.$refs.setuserModal.getUserInfo(record)
      // _this.mdl = record
      _this.visible = true
    },
    handleOk () {
      this.visible = false
      this.confirmLoading = false
      this.$refs.table.refresh()
    },
    handleCancel () {
      this.visible = false
      // const form = this.$refs.setuserModal.form
      // form.resetFields() // 清理表单数据（可不做）
    },
    handleSub (record) {
      if (record.status !== 0) {
        this.$message.info(`${record.no} 订阅成功`)
      } else {
        this.$message.error(`${record.no} 订阅失败，规则已关闭`)
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    resetSearchForm () {
      this.queryParam = {
        userName: '',
        mobile: '',
        userId: 0,
        status: 9
      }
    },
    gotoRangePower (userId) {
      this.$router.push({ path: '/syueuser/range-power?userId=' + userId })
    },
    gotoAssignClassify (userInfoId, userCenterId) {
      var info = {
        userId: userInfoId,
        userCenterId: userCenterId,
        classifyIdArr: []
      }
      this.$refs.assignclassify.ininData(info)
    },
    gotoAssignPoint (userInfoId, userCenterId) {
      var info = {
        userId: userInfoId,
        userCenterId: userCenterId,
        gridIdArr: []
      }
      this.$refs.assignpoint.ininData(info)
    }
  }
}
</script>
